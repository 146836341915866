export const CampusListData = () => {
  const lang = (localStorage.getItem('currentLangValue') === "" || localStorage.getItem('currentLangValue') === null) ? "tr" : localStorage.getItem('currentLangValue') ;
  if(lang === 'tr')
  {
    return [
      { value: 'Ulus', label: 'Ulus Ulusal Kampüsü' },
      { value: 'UlusInternational', label: 'Ulus International Campus' },
      { value: 'BahceSehir', label: 'Bahçeşehir Kampüsü' },
      { value: 'Balmumcu', label: 'Balmumcu Kampüsü' },
      { value: 'IzmirInternational', label: 'İzmir International Campus' }
    ];
  }
  else
  {
    return [

      { value: 'Ulus', label: 'Ulus National Campus' },
      { value: 'UlusInternational', label: 'Ulus International Campus' },
      { value: 'BahceSehir', label: 'Bahçeşehir Campus' },
      { value: 'Balmumcu', label: 'Balmumcu Campus' },
      { value: 'IzmirInternational', label: 'İzmir Uluslararası Campus' }
    ];
  }
  };

  export const SchoolListData = () => {
    const lang = (localStorage.getItem('currentLangValue') === "" || localStorage.getItem('currentLangValue') === null) ? "tr" : localStorage.getItem('currentLangValue') ;
    if(lang === 'tr')
    {
      return [
          { value: 'Anaokulu', label: 'Anaokulu' , campuses:['Ulus','Balmumcu','BahceSehir']  },
          { value: 'Ilkokul', label: 'İlkokul', campuses:['Ulus','BahceSehir'] },
          { value: 'Ortaokul', label: 'Ortaokul', campuses:['Ulus','BahceSehir']  },
          { value: 'Lise', label: 'Lise', campuses:['Ulus','BahceSehir']  },

          { value: 'FenTeknolojiLise', label: 'Fen ve Teknoloji Lisesi', campuses:['Ulus']  },
          { value: 'IGCSE', label: 'IGCSE', campuses:['Ulus']  },
          { value: 'IB', label: 'IB', campuses:['Ulus']  },

         
          { value: 'PreSchool', label: 'Pre School' , campuses:['UlusInternational']  },
          { value: 'PreKindergarten', label: 'Pre Kindergarten', campuses:['UlusInternational'] },

          { value: 'EarlyYears', label: 'Early Years' , campuses:['IzmirInternational']  },
          
          { value: 'Kindergarten', label: 'Kindergarten', campuses:['UlusInternational','IzmirInternational']  },
          { value: 'Primary', label: 'Primary school', campuses:['UlusInternational','IzmirInternational']  },
          { value: 'Middle', label: 'Middle School', campuses:['UlusInternational','IzmirInternational']  },
          { value: 'Secondary', label: 'Secondary School', campuses:['UlusInternational','IzmirInternational']  },
          { value: 'IB', label: 'IB', campuses:['UlusInternational','IzmirInternational']  }

        ];
    }
    else
    {
      return [
          { value: 'Anaokulu', label: 'Kindergarten' , campuses:['Ulus' ,'Balmumcu','BahceSehir']  },
          { value: 'Ilkokul', label: 'Primary school', campuses:['Ulus','BahceSehir'] },
          { value: 'Ortaokul', label: 'Middle school', campuses:['Ulus','BahceSehir']  },
          { value: 'Lise', label: 'High school', campuses:['Ulus','BahceSehir']  },

          { value: 'FenTeknolojiLise', label: 'Science Technology High School', campuses:['Ulus']  },
          { value: 'IGCSE', label: 'IGCSE', campuses:['Ulus']  },
          { value: 'IB', label: 'IB', campuses:['Ulus']  },

          { value: 'PreSchool', label: 'Pre School' , campuses:['UlusInternational']  },
          { value: 'PreKindergarten', label: 'Pre Kindergarten', campuses:['UlusInternational'] },

          { value: 'EarlyYears', label: 'Early Years' , campuses:['IzmirInternational']  },

          { value: 'Kindergarten', label: 'Kindergarten', campuses:['UlusInternational','IzmirInternational']  },
          { value: 'Primary', label: 'Primary school', campuses:['UlusInternational','IzmirInternational']  },
          { value: 'Middle', label: 'Middle School', campuses:['UlusInternational','IzmirInternational']  },
          { value: 'Secondary', label: 'Secondary School', campuses:['UlusInternational','IzmirInternational']  },
          { value: 'IB', label: 'IB', campuses:['UlusInternational','IzmirInternational']  }
      ];
    }
  };

  export const ClassListData = () => {
  
    const lang = (localStorage.getItem('currentLangValue') === "" || localStorage.getItem('currentLangValue') === null) ? "tr" : localStorage.getItem('currentLangValue') ;
    if(lang === 'tr')
    {
      return  [
        { value: 'BigGroup', label: '5-6 Yaş' , school:'Anaokulu' ,campuses:['Ulus' ,'Balmumcu','BahceSehir'] },
        { value: 'MediumGroup', label: '4-5 Yaş' , school:'Anaokulu' ,campuses:['Balmumcu','BahceSehir'] },
        { value: 'SmallGroup', label: '3-4 Yaş' , school:'Anaokulu' ,campuses:['Balmumcu'] },
        { value: 'Class1', label: '1. Sınıf', school:'Ilkokul' , campuses:['Balmumcu','Ulus','BahceSehir']  },
        { value: 'Class2', label: '2. Sınıf', school:'Ilkokul' , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class3', label: '3. Sınıf', school:'Ilkokul' , campuses:['Balmumcu','Ulus','BahceSehir']  },
        { value: 'Class4', label: '4. Sınıf', school:'Ilkokul' , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class5', label: '5. Sınıf', school:'Ortaokul' , campuses:['Balmumcu','Ulus','BahceSehir']  },
        { value: 'Class6', label: '6. Sınıf', school:'Ortaokul'  , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class7', label: '7. Sınıf', school:'Ortaokul'  , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class8', label: '8. Sınıf', school:'Ortaokul' , campuses:['Balmumcu','Ulus','BahceSehir']  },
        { value: 'Prepare', label: 'Hazırlık', school:'Lise'  , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class9', label: '9. Sınıf', school:'Lise'  , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class10', label: '10. Sınıf', school:'Lise' , campuses:['Balmumcu','Ulus','BahceSehir']  },
        { value: 'Class11', label: '11. Sınıf', school:'Lise'  , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class12', label: '12. Sınıf', school:'Lise'  , campuses:['Balmumcu','Ulus','BahceSehir'] },

        { value: 'Prepare', label: 'Hazırlık', school:'FenTeknolojiLise'  , campuses:['Ulus'] },
        { value: 'Class9', label: '9. Sınıf', school:'FenTeknolojiLise'  , campuses:['Ulus'] },
        { value: 'Class10', label: '10. Sınıf', school:'FenTeknolojiLise' , campuses:['Ulus']  },
        { value: 'Class11', label: '11. Sınıf', school:'FenTeknolojiLise'  , campuses:['Ulus'] },
        { value: 'Class12', label: '12. Sınıf', school:'FenTeknolojiLise'  , campuses:['Ulus'] },

        
        { value: 'Class11', label: 'IGCSE-1', school:'IGCSE'  , campuses:['Ulus'] },
        { value: 'Class12', label: 'IGCSE-2', school:'IGCSE'  , campuses:['Ulus'] },

        { value: 'Class11', label: 'IB-1', school:'IB'  , campuses:['Ulus'] },
        { value: 'Class12', label: 'IB-2', school:'IB'  , campuses:['Ulus'] },

        { value: 'Year3', label: 'Year 3', school:'PreSchool' , campuses:['UlusInternational'] },
        { value: 'Year3', label: 'Year 3', school:'PreKindergarten' , campuses:['UlusInternational'] },
        { value: 'Year4', label: 'Year 4', school:'PreKindergarten' , campuses:['UlusInternational'] },

        { value: 'Year5', label: 'Year 5', school:'Kindergarten' , campuses:['UlusInternational','IzmirInternational'] },
        
        { value: 'Grade1', label: 'Grade-1', school:'Primary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade2', label: 'Grade-2', school:'Primary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade3', label: 'Grade-3', school:'Primary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade4', label: 'Grade-4', school:'Primary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade5', label: 'Grade-5', school:'Primary' , campuses:['UlusInternational','IzmirInternational'] },

        
        { value: 'Grade6', label: 'Grade-6', school:'Middle' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade7', label: 'Grade-7', school:'Middle' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade8', label: 'Grade-8', school:'Middle' , campuses:['UlusInternational','IzmirInternational'] },
        
        { value: 'Grade9', label: 'Grade-9', school:'Secondary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade10', label: 'Grade-10', school:'Secondary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade11', label: 'Grade-11', school:'Secondary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade12', label: 'Grade-12', school:'Secondary' , campuses:['UlusInternational','IzmirInternational'] },

        { value: 'IB1', label: 'IB-1', school:'IB' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'IB2', label: 'IB-2', school:'IB' , campuses:['UlusInternational','IzmirInternational'] },
        
        { value: 'R1', label: 'R1', school:'EarlyYears' , campuses:['IzmirInternational'] },
        { value: 'R2', label: 'R2', school:'EarlyYears' , campuses:['IzmirInternational'] },
      ];
    }
    else
    {
      return  [
        { value: 'BigGroup', label: '5-6 Years Old' , school:'Anaokulu' ,campuses:['Ulus', 'Balmumcu','BahceSehir'] },
        { value: 'MediumGroup', label: '4-5 Years Old' , school:'Anaokulu' ,campuses:['Balmumcu','BahceSehir'] },
        { value: 'SmallGroup', label: '3-4 Years Old' , school:'Anaokulu' ,campuses:['Balmumcu'] },
        { value: 'Class1', label: '1st Class', school:'Ilkokul', campuses:['Balmumcu','Ulus','BahceSehir']  },
        { value: 'Class2', label: '2nd Class', school:'Ilkokul', campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class3', label: '3rd Class', school:'Ilkokul' , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class4', label: '4th Class', school:'Ilkokul', campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class5', label: '5th Class', school:'Ortaokul' , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class6', label: '6th Class', school:'Ortaokul', campuses:['Balmumcu','Ulus','BahceSehir']  },
        { value: 'Class7', label: '7th Class', school:'Ortaokul', campuses:['Balmumcu','Ulus','BahceSehir']  },
        { value: 'Class8', label: '8th Class', school:'Ortaokul', campuses:['Balmumcu','Ulus','BahceSehir']  },
        { value: 'Prepare', label: 'Preparation', school:'Lise' , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class9', label: '9th Class', school:'Lise', campuses:['Balmumcu','Ulus','BahceSehir']  },
        { value: 'Class10', label: '10th Class', school:'Lise' , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class11', label: '11th Class', school:'Lise' , campuses:['Balmumcu','Ulus','BahceSehir'] },
        { value: 'Class12', label: '12th Class', school:'Lise' , campuses:['Balmumcu','Ulus','BahceSehir'] },


        { value: 'Prepare', label: 'Preparation', school:'FenTeknolojiLise'  , campuses:['Ulus'] },
        { value: 'Class9', label: '9th Class', school:'FenTeknolojiLise'  , campuses:['Ulus'] },
        { value: 'Class10', label: '10th Class', school:'FenTeknolojiLise' , campuses:['Ulus']  },
        { value: 'Class11', label: '11th Class', school:'FenTeknolojiLise'  , campuses:['Ulus'] },
        { value: 'Class12', label: '12th Class', school:'FenTeknolojiLise'  , campuses:['Ulus'] },

        
        { value: 'Class11', label: 'IGCSE-1', school:'IGCSE'  , campuses:['Ulus'] },
        { value: 'Class12', label: 'IGCSE-2', school:'IGCSE'  , campuses:['Ulus'] },

        { value: 'Class11', label: 'IB-1', school:'IB'  , campuses:['Ulus'] },
        { value: 'Class12', label: 'IB-2', school:'IB'  , campuses:['Ulus'] },

        { value: 'Year3', label: 'Year 3', school:'PreSchool' , campuses:['UlusInternational'] },
        { value: 'Year3', label: 'Year 3', school:'PreKindergarten' , campuses:['UlusInternational'] },
        { value: 'Year4', label: 'Year 4', school:'PreKindergarten' , campuses:['UlusInternational'] },

        { value: 'Year5', label: 'Year 5', school:'Kindergarten' , campuses:['UlusInternational','IzmirInternational'] },
        
        { value: 'Grade1', label: 'Grade-1', school:'Primary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade2', label: 'Grade-2', school:'Primary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade3', label: 'Grade-3', school:'Primary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade4', label: 'Grade-4', school:'Primary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade5', label: 'Grade-5', school:'Primary' , campuses:['UlusInternational','IzmirInternational'] },

        
        { value: 'Grade6', label: 'Grade-6', school:'Middle' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade7', label: 'Grade-7', school:'Middle' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade8', label: 'Grade-8', school:'Middle' , campuses:['UlusInternational','IzmirInternational'] },
        
        { value: 'Grade9', label: 'Grade-9', school:'Secondary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade10', label: 'Grade-10', school:'Secondary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade11', label: 'Grade-11', school:'Secondary' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'Grade12', label: 'Grade-12', school:'Secondary' , campuses:['UlusInternational','IzmirInternational'] },

        { value: 'IB1', label: 'IB-1', school:'IB' , campuses:['UlusInternational','IzmirInternational'] },
        { value: 'IB2', label: 'IB-2', school:'IB' , campuses:['UlusInternational','IzmirInternational'] },
        
        { value: 'R1', label: 'R1', school:'EarlyYears' , campuses:['IzmirInternational'] },
        { value: 'R2', label: 'R2', school:'EarlyYears' , campuses:['IzmirInternational'] },
      ];
    }
   
  };

  export const PeriodListData = () => {
    return [ 
        { value: '2024-2025', label: '2024-2025' },
        { value: '2023-2024', label: '2023-2024' },
        { value: '2022-2023', label: '2022-2023' },
        { value: '2021-2022', label: '2021-2022' },
        { value: '2020-2021', label: '2020-2021' }       
      ];
  };

  export const Currencies = () => {    
      return  [
       
      ];
  };

  export const ActivitiesCampusListData = () => {
    const lang = (localStorage.getItem('currentLangValue') === "" || localStorage.getItem('currentLangValue') === null) ? "tr" : localStorage.getItem('currentLangValue') ;
    if(lang === 'tr')
    {
      return [
        { value: 'Ulus', label: 'Ulus Ulusal Kampüsü' },
        { value: 'UlusInternational', label: 'Ulus International Campus' },
        //{ value: 'BahceSehir', label: 'Bahçeşehir Kampüsü' },
        //{ value: 'IzmirInternational', label: 'İzmir International Campus' }
      ];
    }
    else
    {
      return [
  
        { value: 'Ulus', label: 'Ulus National Campus' },
        { value: 'UlusInternational', label: 'Ulus International Campus' },
        //{ value: 'BahceSehir', label: 'Bahçeşehir Campus' },
        //{ value: 'IzmirInternational', label: 'İzmir Uluslararası Campus' }
      ];
    }
    };

    export const ActivitiesCategoryData = () => {
      const lang = (localStorage.getItem('currentLangValue') === "" || localStorage.getItem('currentLangValue') === null) ? "tr" : localStorage.getItem('currentLangValue') ;
      if(lang === 'tr')
      {
        return [
            { value: 'Anaokulu', label: 'ANAOKULU' , campuses:['Ulus']  },
            { value: 'Muzik', label: 'Muzik Dersleri' , campuses:['Ulus','UlusInternational']  },
            { value: 'GorselSanatlar', label: 'Görsel Sanatlar' , campuses:['Ulus','UlusInternational']  },
            { value: 'SahneSanatlar', label: 'Sahne Sanatlari' , campuses:['Ulus','UlusInternational']  },
            { value: 'Bilisim', label: 'Bilişim' , campuses:['Ulus','UlusInternational']  },
  
          ];
      }
      else
      {
        return [
          { value: 'Anaokulu', label: 'KINDERGARTEN' , campuses:['Ulus']  },
          { value: 'Muzik', label: 'Music Lessons' , campuses:['Ulus','UlusInternational']  },
          { value: 'GorselSanatlar', label: 'Visual Arts' , campuses:['Ulus','UlusInternational']  },
          { value: 'SahneSanatlar', label: 'Performing Arts' , campuses:['Ulus','UlusInternational']  },
          { value: 'Bilisim', label: 'Informatics' , campuses:['Ulus','UlusInternational']  },
        ];
      }
    };
  
    export const ActivitiesBranchData  = () => {
  
      const lang = (localStorage.getItem('currentLangValue') === "" || localStorage.getItem('currentLangValue') === null) ? "tr" : localStorage.getItem('currentLangValue') ;
      if(lang === 'tr')
      {
        return  [ 

          { value: 'Piyano', price:20000 , label: 'Piyano' , activityCategory:'Muzik' ,campuses:['Ulus','UlusInternational'] },
          { value: 'Gitar', price:20000 , label: 'Gitar' , activityCategory:'Muzik' ,campuses:['Ulus','UlusInternational'] },
          { value: 'Keman', price:20000 , label: 'Keman' , activityCategory:'Muzik' ,campuses:['Ulus','UlusInternational'] },
          { value: 'Bateri', price:20000 , label: 'Bateri' , activityCategory:'Muzik' ,campuses:['Ulus','UlusInternational'] },

          
          { value: 'GenelPicture', price:11250 , label: 'Genel Resim' , activityCategory:'GorselSanatlar' ,campuses:['Ulus','UlusInternational'] },
          { value: 'ResimIllustrasyon', price:11250 , label: 'Resim & İllüstrasyon' , activityCategory:'GorselSanatlar' ,campuses:['Ulus','UlusInternational'] },
          { value: 'Seramik', price:11250 , label: 'Seramik' , activityCategory:'GorselSanatlar' ,campuses:['Ulus','UlusInternational'] },

          { value: 'YaraticiDrama', price:11250 , label: 'Yaratıcı Drama' , activityCategory:'SahneSanatlar' ,campuses:['Ulus','UlusInternational'] },
          { value: 'IngilizceDrama', price:11250 , label: 'İngilizce Drama' , activityCategory:'SahneSanatlar' ,campuses:['Ulus','UlusInternational'] },
          { value: 'ModernDans', price:11250 , label: 'Modern Dans' , activityCategory:'SahneSanatlar' ,campuses:['Ulus','UlusInternational'] },
          { value: 'HalkDans', price:11250 , label: 'Halk Dansları' , activityCategory:'SahneSanatlar' ,campuses:['Ulus','UlusInternational'] },
          
          { value: 'RobotikKodlama', price:15000 , label: 'Robotik Kodlama' , activityCategory:'Bilisim' ,campuses:['Ulus','UlusInternational'] },

          
          { value: 'RitimPerkusyon', price:10000 , label: 'Ritim & Perküsyon' , activityCategory:'Anaokulu' ,campuses:['Ulus'] },
          { value: 'ModernDans', price:10000 , label: 'Modern Dans' , activityCategory:'Anaokulu' ,campuses:['Ulus'] },
          { value: 'YaraticiDrama', price:10000 , label: 'Yaratıcı Drama' , activityCategory:'Anaokulu' ,campuses:['Ulus'] },
          { value: 'Muzik', price:10000 , label: 'Müzik' , activityCategory:'Anaokulu' ,campuses:['Ulus'] },
          { value: 'RobotikKodlama', price:10000 , label: 'Robotik Kodlama' , activityCategory:'Anaokulu' ,campuses:['Ulus'] },
        
        ];
      }
      else  
      {
        return  [
          { value: 'Piyano', price:20000 , label: 'Piano' , activityCategory:'Muzik' ,campuses:['Ulus','UlusInternational'] },
          { value: 'Gitar', price:20000 , label: 'Guitar' , activityCategory:'Muzik' ,campuses:['Ulus','UlusInternational'] },
          { value: 'Keman', price:20000 , label: 'Violin' , activityCategory:'Muzik' ,campuses:['Ulus','UlusInternational'] },
          { value: 'Bateri', price:20000 , label: 'Drums' , activityCategory:'Muzik' ,campuses:['Ulus','UlusInternational'] },

          
          { value: 'GenelPicture', price:11250 , label: 'General Picture' , activityCategory:'GorselSanatlar' ,campuses:['Ulus','UlusInternational'] },
          { value: 'ResimIllustrasyon', price:11250 , label: 'Painting and Illustration' , activityCategory:'GorselSanatlar' ,campuses:['Ulus','UlusInternational'] },
          { value: 'Seramik', price:11250 , label: 'Ceramic' , activityCategory:'GorselSanatlar' ,campuses:['Ulus','UlusInternational'] },

          { value: 'YaraticiDrama', price:11250 , label: 'Creative Drama' , activityCategory:'SahneSanatlar' ,campuses:['Ulus','UlusInternational'] },
          { value: 'IngilizceDrama', price:11250 , label: 'English Drama' , activityCategory:'SahneSanatlar' ,campuses:['Ulus','UlusInternational'] },
          { value: 'ModernDans', price:11250 , label: 'Modern Dance' , activityCategory:'SahneSanatlar' ,campuses:['Ulus','UlusInternational'] },
          { value: 'HalkDans', price:11250 , label: 'Folk Dances' , activityCategory:'SahneSanatlar' ,campuses:['Ulus','UlusInternational'] },
          
          { value: 'RobotikKodlama', price:15000 , label: 'Robotic Coding' , activityCategory:'Bilisim' ,campuses:['Ulus','UlusInternational'] },

          
          { value: 'RitimPerkusyon', price:10000 , label: 'Rhythm and Percussion' , activityCategory:'Anaokulu' ,campuses:['Ulus'] },
          { value: 'ModernDans', price:10000 , label: 'Modern Dance' , activityCategory:'Anaokulu' ,campuses:['Ulus'] },
          { value: 'YaraticiDrama', price:10000 , label: 'Creative Drama' , activityCategory:'Anaokulu' ,campuses:['Ulus'] },
          { value: 'Muzik', price:10000 , label: 'Music' , activityCategory:'Anaokulu' ,campuses:['Ulus'] },
          { value: 'RobotikKodlama', price:10000 , label: 'Robotic Coding' , activityCategory:'Anaokulu' ,campuses:['Ulus'] },


        ];
      }
     
    };
  
    export const  ActivityPeriodListData = () => {
      return [
          { value: '2024-2025-3', label: '2024-2025 3. Çeyrek' , factor:1 ,categories:['Muzik' , 'GorselSanatlar' , 'SahneSanatlar' , 'Bilisim' ] },
          { value: '2024-2025-4', label: '2024-2025 4. Çeyrek' , factor:1,categories:['Muzik' , 'GorselSanatlar' , 'SahneSanatlar' , 'Bilisim']},
          { value: '2024-2025-34', label: '2024-2025 3. ve 4. Çeyrek', factor:2 ,categories:['Muzik' , 'GorselSanatlar' , 'SahneSanatlar' , 'Bilisim']}   ,
          
          { value: '1-Ceyrek-8Hafta', label: '3. Çeyrek 8 Hafta' , factor: 1 , categories:['Anaokulu']},
          { value: '2-Ceyrek-9Hafta', label: '4. Çeyrek 9 Hafta', factor: 1.125 , categories:['Anaokulu']}         
        ];
    };
    
    






    
    
    
    
    
    
         